import { communityFileInputManager } from '@@api/fileapi';
import { getTemplates } from '@@modules/community/api/template';
import { States } from '@@services/services';
import { AsyncButton } from '@inwink/buttons';
import { Entities } from '@inwink/entities/entities';
import { EntityForm, IEntityFormState } from '@inwink/entityform';
import { getDefaultValues } from '@inwink/entityform/utils';
import { AppTextLabel } from '@inwink/i18n';
import { Loader } from '@inwink/loader';
import * as React from 'react';

export interface ICommunityOnlineProps {
    entityValue: any;
    community: States.ICommunityState;
    i18n: States.i18nState;
    entityName: string;
    onValidate: (entityValue, patch?) => Promise<any>;
    template?: Entities.IEntityTemplate;
    defaultValues?: any;
    sectionClassName?: string;
    className?: string;
    readOnly?: boolean;
    customComponentProvider?: (fieldtemplate: Entities.IEntityFieldTemplate,
        formtemplate: Entities.IExtendedFieldsFormFieldTemplate, entityName: string) => any;
    onEntityValueChanged?: (entityValue, patch?) => void;
    datacontext?: any;
    footerClassName?: string;
    customActionName?: string;
    formTemplate?: Entities.IExtendedFieldsFormTemplate;
    roleMissing?: boolean;
}

export const CommunityOnlineForm = (props: ICommunityOnlineProps) => {
    const fileInputManager = communityFileInputManager(props.community.requestManagers);
    const [entityValue, setEntityValue] = React.useState(props.entityValue);
    const [templateLoaded, setTemplateLoaded] = React.useState(false);
    const [formTemplateLoaded, setFormTemplateLoaded] = React.useState(false);
    const [formTemplate, setFormTemplate] = React.useState(null);
    const [entityTemplate, setEntityTemplate] = React.useState(null);
    const [computed, setComputed] = React.useState(null);
    const [patch, setPatch] = React.useState(null);
    const [defaultValues, setDefaultValues] = React.useState(props.defaultValues);
    const [formstate, setFormstate] = React.useState(null);
    const [showvalidations, setShowValidations] = React.useState(null);
    const [scrollFirstErrorInView, setScrollFirstErrorInView] = React.useState(new Date());

    React.useEffect(() => {
        loadTemplates();
    }, []);

    React.useEffect(() => {
        if (entityValue !== props.entityValue) {
            setEntityValue(props.entityValue);
        }
    }, [props.entityValue]);

    React.useEffect(() => {
        if (props.formTemplate !== formTemplate) {
            loadTemplates();
        }
    }, [props.formTemplate]);

    const loadTemplates = (arg?: React.MouseEvent<any>) => {
        if (arg) {
            arg.stopPropagation();
            arg.preventDefault();
        }

        const promises = [];
        if (props.template) {
            setEntityTemplate(props.template);
            setTemplateLoaded(true);
        } else {
            promises.push(getTemplates(props.community.requestManagers, [props.entityName]).then((entitytemplate) => {
                if (entitytemplate?.length) {
                    setEntityTemplate(entitytemplate[0]?.template);
                    setTemplateLoaded(true);
                }

            }, (err) => {
                console.error(err);
                setTemplateLoaded(true);
            }));
        }

        if (props.formTemplate) {
            const dfltValues = getDefaultValues(props.formTemplate.groups, props.defaultValues);
            let value = props.entityValue;
            if (!value) {
                value = dfltValues;
            }
            setEntityValue(value);
            setDefaultValues(dfltValues);
            setFormTemplate(props.formTemplate);
            setFormTemplateLoaded(true);
        }

        return Promise.all(promises);
    };

    const validationChanged = (state: IEntityFormState) => {
        setFormstate(state);
    };

    const _onSubmitEventHandler = (arg: React.MouseEvent<any>) => {
        if (arg) {
            arg.preventDefault();
            arg.stopPropagation();
        }

        if (formstate?.isComputing) {
            return;
        }

        if (props.roleMissing) {
            return;
        }

        if (formstate?.validations?.length) {
            setShowValidations(true);
            setScrollFirstErrorInView(new Date());
        } else {
            const result = Object.assign({}, entityValue, computed);
            const newPatch = Object.assign({}, patch, computed);
            return props.onValidate(result, newPatch);
        }
    };

    const entityFormOnValueChangedEventHandler = (value: any, state, newPatch, newComputed) => {
        setEntityValue(value);
        setFormstate(state);
        setPatch(newPatch);
        setComputed(newComputed);
        if (props.onEntityValueChanged) {
            props.onEntityValueChanged(value, newPatch);
        }
    };

    if (!templateLoaded || !formTemplateLoaded) {
        return <div className={"onlineform loading " + props.className}>
            <section className={props.sectionClassName}>
                <Loader />
            </section>
        </div>;
    }

    if (!entityTemplate || !formTemplate) {
        return <div className={"onlineform error " + props.className}>
            <section className={props.sectionClassName}>
                <AppTextLabel i18n="error.dataload" component="div" />
                <AsyncButton onClick={loadTemplates}><AppTextLabel i18n="actions.retry" /></AsyncButton>
            </section>
        </div>;
    }

    return <div className={"onlineform " + props.className}>
        <section className={props.sectionClassName}>
            <EntityForm
                fileinputmanager={fileInputManager}
                displayLanguage={props.i18n.currentLanguageCode}
                languages={props.community?.detail?.configuration?.global?.supportedLanguages}
                forceReadOnly={props.readOnly}
                entityName={props.entityName}
                entityTemplate={entityTemplate}
                entityFormTemplate={formTemplate && formTemplate.groups}
                computedProperties={formTemplate && formTemplate.computedProperties}
                applyComputedProperties={formTemplate && formTemplate.applyComputedProperties}
                onValidationChanged={validationChanged}
                entityValue={entityValue}
                onValueChanged={entityFormOnValueChangedEventHandler}
                customComponentProvider={props.customComponentProvider}
                defaultValues={defaultValues}
                showValidations={showvalidations}
                scrollFirstErrorInView={scrollFirstErrorInView}
                datacontext={props.datacontext}
            />
        </section>
        <footer className={props.footerClassName}>
            <div className="middle" />
            {!props.readOnly
                ? <AsyncButton className="save" data-role="submit" onClick={_onSubmitEventHandler}>
                    <AppTextLabel component="span" i18n={props.customActionName || "actions.save"} />
                </AsyncButton> : null}
        </footer>
    </div>;
};

CommunityOnlineForm.defaultProps = {
    className: "",
    sectionClassName: "",
    footerClassName: ""
};
